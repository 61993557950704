import styled, { css } from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";
import { StyledRowProps, StyledTheadProps } from "./table.interface";
import { RowProps } from "./table.interface";
import { Text } from "components/atoms/text/text.component";

export const StyledTable = styled.table`
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0 2px;

    th {
        text-align: left;
    }

    .right-panel-row {
        position: relative;
        width: 36px;
        min-width: 36px;
        height: 44px;
        padding: 0;
        background: ${colors.white};

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }

        &::before {
            position: absolute;
            content: "";
            display: inline-block;
            width: 6px;
            left: -6px;
            height: 100%;
            padding: 0;
            top: 0;
            background: linear-gradient(
                270deg,
                rgb(0 0 0 / 6%) 0%,
                rgb(0 0 0 / 0%) 100%
            );
        }

        .mantine-Popover-dropdown {
            padding: 10px 0;
            width: auto !important;
            top: 0 !important;
            margin-left: 6px;
        }
    }
`;

export const StyledTr = styled.tr<StyledRowProps>`
    border: 1px solid ${colors.blue_3};
    border-radius: 5px;
    position: relative;

    &:hover {
        ${({ isClickable }) =>
            isClickable &&
            css`
                cursor: pointer;
            `};
        td {
            border-color: ${colors.purple_3};
        }
    }

    ${({ activeRowId, rowId }) =>
        activeRowId &&
        activeRowId === rowId &&
        css`
            td {
                border-color: ${colors.purple_3} !important;
            }
        `}
`;

export const StyledTd = styled.td<RowProps>`
    position: relative;
    padding: 11px 20px;
    border-top: 1px solid ${colors.blue_3};
    border-bottom: 1px solid ${colors.blue_3};
    background: ${colors.white};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};
    width: 200px;

    &:first-child {
        border-left: 1px solid ${colors.blue_3};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 200px;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            width: auto;
        }
    }

    &:nth-last-child(2) {
        ${({ isHaveActions }) =>
            isHaveActions &&
            css`
                width: auto;
            `}
    }

    &:last-child {
        border-right: 1px solid ${colors.blue_3};
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        ${({ isHaveActions }) =>
            !isHaveActions &&
            css`
                width: auto !important;
            `}
        @media (max-width: ${`${breakpoints.sm}px`}) {
            text-align: right;
        }
    }
`;

export const StyledTh = styled.th<RowProps>`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.black_1};
    padding: 11px 20px;
    border-top: 1px solid ${colors.blue_3};
    border-bottom: 1px solid ${colors.blue_3};
    background: ${colors.white};
    text-transform: capitalize;
    width: 200px;

    &:first-child {
        border-left: 1px solid ${colors.blue_3};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0;
        width: 200px;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            width: auto;
        }
    }

    &:nth-last-child(2) {
        ${({ isHaveActions }) =>
            isHaveActions &&
            css`
                width: auto;
            `}
    }

    &:last-child {
        border-right: 1px solid ${colors.blue_3};
        border-top-right-radius: 5px;
        border-bottom-right-radius: 0;

        &.right-panel-head {
            width: 36px !important;
            padding: 11px 14px;
        }

        ${({ isHaveActions }) =>
            !isHaveActions &&
            css`
                width: auto !important;
            `}
    }
`;
export const StyledThead = styled.thead<StyledTheadProps>`
    @media (max-width: ${`${breakpoints.sm}px`}) {
        ${({ showHeadInMobile }) => !showHeadInMobile && "display: none;"}
    }
`;
export const StyledTbody = styled.tbody``;

export const StyledMessage = styled(Text)`
    margin-top: 15px;
`;

export const StyledLoaderBlock = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;
