import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";

export const StyledAvatarBlock = styled.div`
    display: flex;
    min-width: 30px;
`;

export const StyledUserInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledUsername = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: ${colors.black_1};
    margin: 0;
`;

export const StyledRole = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: ${colors.black_2};
`;

export const StyledTabletHeaderContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

export const StyledUsersActionsLeftBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
`;

export const StyledUsersActionsRightBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const StyledFilterMobileButton = styled.button`
    min-width: 24px;
    display: flex;
    padding: 0;
    background: none;
    border: none;
    outline: none;
`;

export const StyledSearchInputBlock = styled.div`
    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: none;
    }
`;
