export const WITHOUT_SYMBOLS_REGEX =
    /^(?!\s)[\p{L}\p{N}\d\s]*[\p{L}\p{N}\d](?!\s)$/gu;

export const INSECURE_PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,15}$/;

export const GAME_TAG_REGEX = /^[\p{L}\p{N}\s.,:'&!()_"#*+;=-]*$/u;

export const REPORT_FOLDER_NAME_REGEX =
    /^(?!\s)[- "#'()*+,.:;<=>|}{~/^_\\[\]`\p{L}\p{N}\\]*$/gu;

export const DYNAMIC_TESTID_NORMALIZE_REGEX = /[\s_]/g;

export const PROVIDER_REGEX = /^[\p{L}\p{N}\s-]*$/u;

export const FORBID_SPECIAL_CHARACTERS_REGEX = /^[\p{L}\p{N}\s]*$/u;

export const FORBID_START_END_2_SPACES_REGEX = /^(?!\s)(?!.*\s$)(?!.* {2}).*$/;
export const FORBID_2_SPACES_REGEX = /^(?!.* {2}).*$/;

export const METRIC_NAME_REGEX =
    /^(?!.* {2})[- "#'()*+,.:;<=>|}{~/^_\\[\]`\p{L}\p{N}\\]{1,255}$/u;

export const METRIC_DESCRIPTION_REGEX =
    /^[- "#'()*+,.:;<=>|}{~/^_\\[\]`\p{L}\p{N}\\]*$/u;
